// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2021-position-letters-js": () => import("./../../../src/pages/2021/position-letters.js" /* webpackChunkName: "component---src-pages-2021-position-letters-js" */),
  "component---src-pages-2021-state-assembly-votes-js": () => import("./../../../src/pages/2021/state-assembly-votes.js" /* webpackChunkName: "component---src-pages-2021-state-assembly-votes-js" */),
  "component---src-pages-2021-state-senate-votes-js": () => import("./../../../src/pages/2021/state-senate-votes.js" /* webpackChunkName: "component---src-pages-2021-state-senate-votes-js" */),
  "component---src-pages-2022-position-letters-js": () => import("./../../../src/pages/2022/position-letters.js" /* webpackChunkName: "component---src-pages-2022-position-letters-js" */),
  "component---src-pages-2023-position-letters-js": () => import("./../../../src/pages/2023/position-letters.js" /* webpackChunkName: "component---src-pages-2023-position-letters-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-elected-officials-js": () => import("./../../../src/pages/elected-officials.js" /* webpackChunkName: "component---src-pages-elected-officials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-more-info-js": () => import("./../../../src/pages/more-info.js" /* webpackChunkName: "component---src-pages-more-info-js" */),
  "component---src-pages-position-letters-js": () => import("./../../../src/pages/position-letters.js" /* webpackChunkName: "component---src-pages-position-letters-js" */),
  "component---src-pages-resolutions-js": () => import("./../../../src/pages/resolutions.js" /* webpackChunkName: "component---src-pages-resolutions-js" */),
  "component---src-pages-voting-record-js": () => import("./../../../src/pages/voting-record.js" /* webpackChunkName: "component---src-pages-voting-record-js" */)
}

